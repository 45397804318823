.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;
  background-color: var(--primary);

  .apexAvatar {
    margin-bottom: 1.5rem;
    border-radius: 100%;
    width: 12rem;
    height: 12rem;
    box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.4);
  }

  .textContainer {
    display: flex;
    text-align: center;
    flex-direction: column;
    font-size: 1.2rem;

    .greeting {
      font-size: 2.5rem;
      font-weight: bolder;
      color: var(--text-primary);
    }

    .description {
      font-weight: bold;
    }
  }

  .socialLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 15px;
    a {
      border-radius: 100%;
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--secondary);
      padding: 10px;
      margin: 5px 8px;
      box-shadow: 0px 2px 15px 1px rgba(0, 0, 0, 0.6);
      transition: 0.2s ease-out;

      svg {
        width: 1.5rem;
        height: 1.5rem; 
      }

      &:hover {
        border-radius: 1.0rem;
        width: 2.3rem;
        height: 2.3rem;
        margin: 0px 15px;
        transition: 0.3s ease-out;

        &#discord {
          background-color: var(--discord);
          svg {
            transition: 0.3s;
            fill: white !important;
          }
        }

        &#twitter {
          background-color: var(--twitter);
          svg {
            transition: 0.3s;
            fill: white !important;
          }
        }

        &#mail {
          background-color: var(--mail);
          svg {
            transition: 0.3s;
            fill: white !important;
          }
        }

        &#github {
          background-color: var(--github);
          svg {
            transition: 0.3s;
            fill: rgb(64, 64, 64) !important;
          }
        }
      }
    }

    #discord {
      transition: 0.3s;
      fill: var(--discord) !important;
    }

    #twitter {
      transition: 0.3s;
      fill: var(--twitter) !important;
    }

    #mail {
      transition: 0.3s;
      fill: var(--mail) !important;
    }

    #github {
      transition: 0.3s;
      fill: white !important;
    }
  }

  .takaLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-weight: bold;
    background-color: var(--secondary);
    padding: 11px 77px 11px 73px;
    border-radius: 2.0rem;
    box-shadow: 0px 2px 15px 1px rgba(0, 0, 0, 0.6);
    transition: 0.3s ease-out;

    &:hover {
      border-radius: 1.0rem;
      padding: 11px 85px 11px 81px;
      background-color: var(--secondary-hover);
    }

    .takaIcon {
      margin-right: 8px;
      width: 1.75rem;
      height: 1.75rem;
    }
  }
}
