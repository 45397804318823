/*

Primary: #1a1a1e;
Secondary: #252528;
Accent: #4e4e4e;

Buttons / Links: #3D9CEF
Buttons / Links (Hover): #3186d1 

Destroy: #ae3838
Destroy (Hover): #8c2525

Modify: #bb610d
Modify (Hover): #88490e

*/

:root {
  --primary: #121212;
  --secondary: #333333;

  --secondary-hover: #505050;

  --mail: #C53624;
  --discord: #5865F2;
  --twitter: #2AA9E0;
  --github: #FFFFFF;

  --text-primary: #00B2D2;
  --text-secondary: #FFFFFF
}

html {
  font-size: 100%;
}

@font-face {
  font-family: 'Product Sans Bold';
  src: url('./fonts/Product Sans Bold.ttf') format('truetype')
}

body {
  color: white;
  margin: 0;
  font-family: 'Product Sans Bold';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
